import Constants from "../components/constants"

/* 
PlacedBet : represents the amount put 
stake : represent the placed bet amount after tax 
*/
class SlipComp {
    constructor(placedbet, total_odds, match_count) {

        this.placedbet = placedbet || 0
        this.total_odds = total_odds || 0
        this.match_count = match_count || 0

    }

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_all_configurations = () => {

        return {

            'MAX_WIN': Constants.MAX_WIN,
            'TAXABLE_WIN': Constants.CONFIG.TAXABLE_WIN,
            'WIN_TAX': Constants.CONFIG.WIN_TAX,
            'TOT_TAX': Constants.CONFIG.TOT_TAX,
            'VAT_TAX': Constants.CONFIG.VAT_TAX,
            "VAT_TAX_LABEL": "VAT",
            'TAX_TYPE': Constants.TAX_TYPE_VAT,
            'WITHHOLDING_TAX': Constants.CONFIG.WITHHOLDTAX,
            'SLIP_SIZE': Constants.DEFAULTSLIPSIZE,
            'NUM_ELIGIBLE_MATCHES': 0
        }

    }

    get_configurations = (configuration_name) => {
        return this.get_all_configurations()[configuration_name]
    }



    get_tax_value = () => {

        if (this.get_configurations('TAX_TYPE') == Constants.TAX_TYPE_TOT)
            return this.get_configurations('TOT_TAX')

        else if (this.get_configurations('TAX_TYPE') == Constants.TAX_TYPE_VAT)
            return this.get_configurations('VAT_TAX')

    }

    get_placed_bet = () => {
        return this.placedbet
    }
    //Represnets the stake 
    get_stake = () => {

        if (this.get_configurations('TAX_TYPE') == Constants.TAX_TYPE_TOT)
            return this.placedbet * (1 - this.get_tax_value())

        else if (this.get_configurations('TAX_TYPE') == Constants.TAX_TYPE_VAT)
            return this.placedbet / (1 + this.get_tax_value())

        return 0

    }

    // Cacluates the tot from the stake 
    get_tot_tax = () => {

        let tot_tax = this.placedbet * this.get_configurations('TOT_TAX')

        return tot_tax

    }

    // Calculates the vat from the stake 
    get_vat_tax = () => {

        let vat_tax = this.get_stake() * this.get_configurations('VAT_TAX')

        return vat_tax

    }

    // this represnets taxes applied on the betting amount. 
    //Vat or Tot are two taxes considered in this stage of the calculation 
    get_initial_tax = () => {

        if (this.get_configurations('TAX_TYPE') == Constants.TAX_TYPE_TOT)
            return this.get_tot_tax()

        else if (this.get_configurations('TAX_TYPE') == Constants.TAX_TYPE_VAT)
            return this.get_vat_tax()

    }

    // represents the maximum winning value with no bonus and before winning tax
    get_win_value = () => {

        let netstake = this.get_stake()
        let tentedstake = this.get_configurations('MAX_WIN') / this.total_odds

        let win_value = netstake * this.total_odds


        if (win_value > this.get_configurations('MAX_WIN')) {
            let extrastake = netstake - tentedstake
            win_value = this.get_configurations('MAX_WIN') + extrastake
        }

        return win_value
    }

    get_bonus_value = () => {

        // if slip bonus is not allowed, return 0

        if (!this.get_configurations('BET_SLIP_BONUS'))
            return 0

        let bonus_value = this.get_initial_tax() * this.total_odds

        return bonus_value
    }

    // calculates the winning tax 
    calculate_tax = () => {

        let win_value = this.get_win_value() + this.get_bonus_value()
        let tax_value = 0

        if (win_value > Constants.CONFIG.TAXABLE_WIN)
            tax_value = Constants.CONFIG.WIN_TAX * win_value

        return tax_value
    }

    get_net_pay = () => {
        let win_value = this.get_win_value()
        let bonus_value = this.get_bonus_value()
        let net_pay = win_value + bonus_value

        let win_tax = this.calculate_tax()
        net_pay = net_pay - win_tax

        return net_pay
    }

    get_note = () => {
        return null
    }

    get_match_count = () => {
        return this.match_count
    }

}

class MulaSlipComp extends SlipComp {

    static ConfigurationDescription = () => ({
    })

    get_win_value = () => {

        let tentedstake = this.get_configurations('MAX_WIN') / this.total_odds
        let win_value = this.placedbet * this.total_odds
        if (win_value > this.get_configurations('MAX_WIN')) {
            let extrastake = this.placedbet - tentedstake
            win_value = this.get_configurations('MAX_WIN') + extrastake
        }
        return win_value
    }

    calculate_tax = () => {
        let win_tax = Constants.CONFIG.WITHHOLDTAX * this.get_win_value()

        return win_tax
    }

    get_net_pay = () => {

        let win_value = this.get_win_value()
        let withhold_tax = this.calculate_tax()
        let net_pay = win_value - withhold_tax
        return net_pay

    }

}

class MulaSlipCompMW5K extends SlipComp {

    static ConfigurationDescription = () => ({
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['MAX_WIN'] = 5000
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_NONE
        return all_configurations[configuration_name]

    }

    get_win_value = () => {

        let tentedstake = this.get_configurations('MAX_WIN') / this.total_odds
        let win_value = this.placedbet * this.total_odds
        if (win_value > this.get_configurations('MAX_WIN')) {
            let extrastake = this.placedbet - tentedstake
            win_value = this.get_configurations('MAX_WIN') + extrastake
        }
        return win_value
    }

    calculate_tax = () => {
        let win_tax = Constants.CONFIG.WITHHOLDTAX * this.get_win_value()

        return win_tax
    }

    get_net_pay = () => {

        let win_value = this.get_win_value()
        let withhold_tax = this.calculate_tax()
        let net_pay = win_value - withhold_tax
        return net_pay

    }

}

class MulaSlipComp10PVatMW5K extends MulaSlipCompMW5K {
    static ConfigurationDescription = () => ({

    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['MAX_WIN'] = 5000
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_VAT
        all_configurations['VAT_TAX'] = 0.1
        all_configurations['VAT_TAX_LABEL'] = "LEVY"

        return all_configurations[configuration_name]

    }

    get_vat_tax = () => {

        return this.placedbet * this.get_configurations('VAT_TAX')

    }

    get_stake = () => {
        return this.placedbet - this.get_vat_tax()
    }

    calculate_tax = () => {
        return 0
    }

    get_win_value = () => {

        let tentedstake = this.get_configurations('MAX_WIN') / this.total_odds
        let win_value = this.get_stake() * this.total_odds
        if (win_value > this.get_configurations('MAX_WIN')) {
            let extrastake = this.get_stake() - tentedstake
            win_value = this.get_configurations('MAX_WIN') + extrastake
        }

        return win_value
    }

}


class MulaSlipComp10PVatMW10K extends MulaSlipComp10PVatMW5K {
    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['MAX_WIN'] = 10000
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_VAT
        all_configurations['VAT_TAX'] = 0.1
        all_configurations['VAT_TAX_LABEL'] = "LEVY"
        all_configurations['SLIP_SIZE'] = 30


        return all_configurations[configuration_name]

    }

}
class LesothoMulaSlipCompMW5K extends MulaSlipCompMW5K {
    static ConfigurationDescription = () => ({
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['MAX_WIN'] = 5000
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_NONE
        return all_configurations[configuration_name]

    }

    calculate_tax = () => {
        return 0
    }
}

class LesothoMulaSlipCompMW10K extends LesothoMulaSlipCompMW5K {
    static ConfigurationDescription = () => ({
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['MAX_WIN'] = 10000
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_NONE
        return all_configurations[configuration_name]

    }

}

class AfroSlipCompNoBonusMW50KSlipSize30 extends SlipComp {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['MAX_WIN'] = 50000
        all_configurations['SLIP_SIZE'] = 30

        return all_configurations[configuration_name]

    }

}

class AfroSlipCompNoBonusMW100KSlipSize50 extends SlipComp {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['MAX_WIN'] = 100000
        all_configurations['SLIP_SIZE'] = 50

        return all_configurations[configuration_name]

    }

}

class AfroSlipCompNoBonusMW350KSlipSize50 extends SlipComp {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 50

        return all_configurations[configuration_name]

    }

}

class AfroSlipCompNoBonusMW500KSlipSize50 extends AfroSlipCompNoBonusMW350KSlipSize50 {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['MAX_WIN'] = 500000
        all_configurations['SLIP_SIZE'] = 50

        return all_configurations[configuration_name]

    }


}

class AfroSlipCompNoBonusMW150KSlipSize50 extends SlipComp {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['MAX_WIN'] = 150000
        all_configurations['SLIP_SIZE'] = 50

        return all_configurations[configuration_name]

    }

}

class AfroSlipCompBonusTOTMW50KSlipSize50 extends SlipComp {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_TOT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_TOT
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['BET_SLIP_BONUS'] = true

        return all_configurations[configuration_name]

    }

}

class AfroSlipCompMW50KSlpSz50BGT100BV10p extends SlipComp {
    /*
        Bonus is considered only if the placed amount is >= 100 
        if the palced amount is >=100, bonus the 10% of the palced amount. 
        The 10% amoutn is not liable to TOT 
    */

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_TOT
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['BET_SLIP_BONUS'] = true

        return all_configurations[configuration_name]

    }

    get_bonus_value = () => {

        // if slip bonus is not allowed, return 0
        let win_value = this.get_win_value()

        if (this.placedbet < 100)
            return 0

        let bonus_value = (this.placedbet * 0.1) * this.total_odds

        if ((win_value + bonus_value) > this.get_configurations('MAX_WIN'))
            bonus_value = this.get_configurations('MAX_WIN') - win_value

        if (bonus_value < 0)
            bonus_value = 0

        return bonus_value
    }


}

class AfroSlipCompMW350KSlpSz50BGT20BV10p extends SlipComp {

    /*
    Bonus is considered only if the placed amount is >= 100 
    if the palced amount is >=100, bonus the 10% of the palced amount. 
    The 10% amoutn is not liable to TOT 
*/

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_TOT
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['BET_SLIP_BONUS'] = true

        return all_configurations[configuration_name]

    }

    get_bonus_value = () => {

        // if slip bonus is not allowed, return 0
        let win_value = this.get_win_value()

        if (this.placedbet < 20)
            return 0

        let bonus_value = (this.placedbet * 0.1) * this.total_odds

        if ((win_value + bonus_value) > this.get_configurations('MAX_WIN'))
            bonus_value = this.get_configurations('MAX_WIN') - win_value

        if (bonus_value < 0)
            bonus_value = 0

        return bonus_value
    }

}

class AfroSlipCompMW350KSlpSz50BGT20BVAT extends AfroSlipCompMW350KSlpSz50BGT20BV10p {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_VAT
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['BET_SLIP_BONUS'] = true

        return all_configurations[configuration_name]

    }
}


class AfroSlipCompMW1MKSlpSz50BGT20BVAT extends AfroSlipCompMW350KSlpSz50BGT20BVAT {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_VAT
        all_configurations['MAX_WIN'] = 1000000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['BET_SLIP_BONUS'] = true

        return all_configurations[configuration_name]

    }
}



class AfroSlipCompMW350KSlpSz50BGT20_1000BV10p extends AfroSlipCompMW350KSlpSz50BGT20BV10p {

    /*
        Bonus is considered only if the placed amount is >= 20  and Win amount > 1000
        bonus the 10% of the palced amount. 
        The 10% amoutn is not liable to TOT 
    */

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_TOT
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['BET_SLIP_BONUS'] = true

        return all_configurations[configuration_name]

    }

    get_bonus_value = () => {

        // if slip bonus is not allowed, return 0
        let win_value = this.get_win_value()

        if (win_value <= 1000)
            return 0

        if (this.placedbet < 20)
            return 0

        let bonus_value = (this.placedbet * 0.1) * this.total_odds

        if ((win_value + bonus_value) > this.get_configurations('MAX_WIN'))
            bonus_value = this.get_configurations('MAX_WIN') - win_value

        if (bonus_value < 0)
            bonus_value = 0

        return bonus_value
    }

}

class AfroSlipCompMW350WinTaxNoStake extends SlipComp {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_VAT
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 20

        return all_configurations[configuration_name]

    }

    calculate_tax = () => {

        let win_value = this.get_win_value() + this.get_bonus_value()
        let tax_value = 0

        if (win_value > Constants.CONFIG.TAXABLE_WIN)
            tax_value = (win_value - this.get_stake()) * Constants.CONFIG.WIN_TAX

        return tax_value
    }

}

class AfroSlipCompNOBnsMW350KRfndNOTLessStake extends AfroSlipCompMW350WinTaxNoStake {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['TAX_TYPE'] = Constants.TAX_TYPE_VAT
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 20

        return all_configurations[configuration_name]

    }

    get_net_pay = () => {
        let win_value = this.get_win_value()
        let bonus_value = this.get_bonus_value()
        let net_pay = win_value + bonus_value

        let win_tax = this.calculate_tax()
        net_pay = net_pay - win_tax

        if (net_pay < this.placedbet)
            net_pay = this.placedbet

        return net_pay
    }

}

class AfroSlipCompBonusMW350KRfndNOTLessStake extends AfroSlipCompNOBnsMW350KRfndNOTLessStake {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 20

        return all_configurations[configuration_name]

    }


    get_bonus_value = () => {

        /** 
        Bonus is calcuated : The initital tax value multiplied 
        by the total odd value. 
        **/

        let win_value = this.get_win_value()

        let bonus_value = this.get_initial_tax() * this.total_odds

        if ((win_value + bonus_value) > this.get_configurations('MAX_WIN'))
            bonus_value = this.get_configurations('MAX_WIN') - win_value

        if (bonus_value < 0)
            bonus_value = 0

        return bonus_value
    }
}


class AfroSlipComp2PRCBnsMW350KRFDNOTLTStake extends AfroSlipCompBonusMW350KRfndNOTLessStake {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 20
        all_configurations['BONUS_PERCENT'] = 0.02

        return all_configurations[configuration_name]

    }

    get_bonus_value = () => {
        /** 
        Bonus is calcuated : The initital tax value multiplied 
        by the total odd value. 
        **/

        let win_value = this.get_win_value()

        let bonus_value = this.get_configurations('BONUS_PERCENT') * win_value

        if ((win_value + bonus_value) > this.get_configurations('MAX_WIN'))
            bonus_value = this.get_configurations('MAX_WIN') - win_value

        if (bonus_value < 0)
            bonus_value = 0

        return bonus_value
    }

}

class AfroSlipComp10PRCBnsMW350KRFDNOTLTStake extends AfroSlipComp2PRCBnsMW350KRFDNOTLTStake {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 20
        all_configurations['BONUS_PERCENT'] = 0.1

        return all_configurations[configuration_name]

    }

}

class AfroSlip10PRCBnsMW150RFNDNOTLTSTKSLSZ50 extends AfroSlipComp10PRCBnsMW350KRFDNOTLTStake {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 150000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['BONUS_PERCENT'] = 0.1

        return all_configurations[configuration_name]

    }

}


class AfroSlipComp10PRCBnsMW1MRFDNOTLTStake extends AfroSlipComp10PRCBnsMW350KRFDNOTLTStake {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 1000000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['BONUS_PERCENT'] = 0.1

        return all_configurations[configuration_name]

    }

}

class AfroSlipComp10PRCBnsGT1000MW1M extends AfroSlipComp10PRCBnsMW1MRFDNOTLTStake {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 1000000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['BONUS_PERCENT'] = 0.1

        return all_configurations[configuration_name]

    }

    get_bonus_value = () => {
        /** 
        Bonus is calcuated : The initital tax value multiplied 
        by the total odd value. 
        **/

        let win_value = this.get_win_value()

        if (win_value < 1000)
            return 0

        let bonus_value = this.get_configurations('BONUS_PERCENT') * win_value

        if ((win_value + bonus_value) > this.get_configurations('MAX_WIN'))
            bonus_value = this.get_configurations('MAX_WIN') - win_value

        if (bonus_value < 0)
            bonus_value = 0

        return bonus_value
    }

    get_net_pay = () => {
        let win_value = this.get_win_value()
        let bonus_value = this.get_bonus_value()
        let net_pay = win_value + bonus_value

        let win_tax = this.calculate_tax()
        net_pay = net_pay - win_tax


        return net_pay
    }

}

class AfroSlipComp10PRCBnsMW1M extends AfroSlipComp10PRCBnsGT1000MW1M {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 1000000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['BONUS_PERCENT'] = 0.1

        return all_configurations[configuration_name]

    }

    get_bonus_value = () => {
        /** 
        Bonus is calcuated : The initital tax value multiplied 
        by the total odd value. 
        **/

        let win_value = this.get_win_value()

        let bonus_value = this.get_configurations('BONUS_PERCENT') * win_value

        if ((win_value + bonus_value) > this.get_configurations('MAX_WIN'))
            bonus_value = this.get_configurations('MAX_WIN') - win_value

        if (bonus_value < 0)
            bonus_value = 0

        return bonus_value
    }
}

class AfroSlipMW150RFNDNOTLTSTKSLSZ50 extends AfroSlipComp10PRCBnsMW350KRFDNOTLTStake {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = false
        all_configurations['MAX_WIN'] = 150000
        all_configurations['SLIP_SIZE'] = 50

        return all_configurations[configuration_name]

    }

    get_bonus_value = () => {
        return 0
    }

    calculate_tax = () => {

        let win_value = this.get_win_value()
        let tax_value = 0

        if (win_value > Constants.CONFIG.TAXABLE_WIN)
            tax_value = win_value * Constants.CONFIG.WIN_TAX

        return tax_value
    }

}

class AfroSlipCompVAT_5PRCBnsMW350KRFNOTLTSTK extends AfroSlipCompBonusMW350KRfndNOTLessStake {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 20
        all_configurations['BONUS_PERCENT'] = 0.05

        return all_configurations[configuration_name]

    }

    calculate_bonus_value = () => {

        /** 
        Bonus is calcuated : The initital tax value multiplied 
        by the total odd value. 
        **/

        let win_value = this.get_win_value()

        let tax_bonus_value = this.get_initial_tax() * this.total_odds
        let win_value_bonus = win_value * this.get_configurations('BONUS_PERCENT')
        let bonus_on_tax_bonus_value = this.get_configurations('BONUS_PERCENT') * tax_bonus_value

        let bonus_value = bonus_on_tax_bonus_value + tax_bonus_value + win_value_bonus

        if ((win_value + bonus_value) > this.get_configurations('MAX_WIN'))
            bonus_value = this.get_configurations('MAX_WIN') - win_value

        if (bonus_value < 0)
            bonus_value = 0

        return bonus_value
    }

    get_bonus_value = () => {
        return this.calculate_bonus_value()
    }

    calculate_tax = () => {

        let win_value = this.get_win_value() + this.get_bonus_value()
        let tax_value = 0

        if (win_value > Constants.CONFIG.TAXABLE_WIN)
            tax_value = win_value * Constants.CONFIG.WIN_TAX

        return tax_value
    }

}

class AfroSlipCompMW350_BNSGT1000_8NOTLTSTK extends AfroSlipCompVAT_5PRCBnsMW350KRFNOTLTSTK {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 20
        all_configurations['BONUS_PERCENT'] = 0.05
        all_configurations['NUM_ELIGIBLE_MATCHES'] = 8

        return all_configurations[configuration_name]

    }

    get_bonus_value = () => {
        let win_value = this.get_win_value()

        if (this.total_odds >= this.get_configurations(
            "NUM_ELIGIBLE_MATCHES"
        ) || win_value > this.get_configurations("TAXABLE_WIN"))
            return this.calculate_bonus_value()

        return 0

    }
}


class AfroSlipCompMW350_BNSGT1000NOTLTSTK extends AfroSlipCompVAT_5PRCBnsMW350KRFNOTLTSTK {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 20
        all_configurations['BONUS_PERCENT'] = 0.05

        return all_configurations[configuration_name]

    }

    get_bonus_value = () => {
        let win_value = this.get_win_value()

        if (win_value > this.get_configurations("TAXABLE_WIN")) {
            return this.calculate_bonus_value()
        }

        return 0

    }
}

class AfroSlipCompMW350_BNSGT1000 extends AfroSlipCompMW350_BNSGT1000NOTLTSTK {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 20
        all_configurations['BONUS_PERCENT'] = 0.05

        return all_configurations[configuration_name]

    }

    get_net_pay = () => {
        let win_value = this.get_win_value()
        let bonus_value = this.get_bonus_value()
        let net_pay = win_value + bonus_value

        let win_tax = this.calculate_tax()
        net_pay = net_pay - win_tax


        return net_pay
    }


}

class AfroSlipCompMW1M_BNSGT1000 extends AfroSlipCompMW350_BNSGT1000NOTLTSTK {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 1000000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['BONUS_PERCENT'] = 0.22

        return all_configurations[configuration_name]

    }

    calculate_bonus_value = () => {

        /** 
        Bonus is calcuated : The initital tax value multiplied 
        by the total odd value. 
        **/

        let win_value = this.get_win_value()


        let bonus_value = this.get_configurations('BONUS_PERCENT') * win_value


        if ((win_value + bonus_value) > this.get_configurations('MAX_WIN'))
            bonus_value = this.get_configurations('MAX_WIN') - win_value

        if (bonus_value < 0)
            bonus_value = 0

        return bonus_value
    }

    get_net_pay = () => {
        let win_value = this.get_win_value()
        let bonus_value = this.get_bonus_value()
        let net_pay = win_value + bonus_value

        let win_tax = this.calculate_tax()
        net_pay = net_pay - win_tax


        return net_pay
    }


}

class MultiBonusMaxBonus100K extends SlipComp {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_NONE
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['MAX_BONUS'] = 100000

        return all_configurations[configuration_name]

    }

    get_min_bonus_eligble_match_count = () => {
        return 8
    }

    get_max_bonus_eligble_match_count = () => {
        return 30
    }

    get_percentages = (match_count) => {
        return {
            8: 0.01,
            9: 0.03,
            10: 0.05,
            11: 0.07,
            12: 0.09,
            13: 0.11,
            14: 0.14,
            15: 0.17,
            16: 0.20,
            17: 0.25,
            18: 0.30,
            19: 0.35,
            20: 0.40,
            21: 0.50,
            22: 0.60,
            23: 0.70,
            24: 0.80,
            25: 1,
            26: 1.2,
            27: 1.4,
            28: 1.6,
            29: 1.9,
            30: 2.0,
        }[match_count]
    }

    is_odd_bonus_eligible = () => {
        return true;
    }


    get_percentage_match_count = () => {
        
        let percentage_match_count = this.get_match_count()

        if ( percentage_match_count  > this.get_max_bonus_eligble_match_count() )
            percentage_match_count = this.get_max_bonus_eligble_match_count()
        
        
        return percentage_match_count

    }
    
    calculate_bonus_value = () => {
        if( !this.is_odd_bonus_eligible() )
            return 0
        let percentage = this.get_percentages(this.get_percentage_match_count())
        let max_percentage = this.get_percentages( this.get_max_bonus_eligble_match_count() )
        if (!percentage)
            return 0
        let win_value = this.get_win_value()
        let max_possible_bonus = win_value * max_percentage
        let bonus_value = win_value * percentage
        
        if(bonus_value > max_possible_bonus)
            bonus_value = max_possible_bonus

        if (bonus_value > this.get_configurations("MAX_BONUS"))
            bonus_value = this.get_configurations("MAX_BONUS")

        return bonus_value
    }
    
    get_bonus_value = () => {
        return this.calculate_bonus_value()
    }

    get_note = () => {

        let percentage = this.get_percentages(this.get_percentage_match_count())
        let note = null
        if (percentage) {
            note = `Current Bonus ${(percentage * 100).toFixed(2)}%`
        }
        else if (this.get_match_count() < this.get_min_bonus_eligble_match_count()) {
            note = `Select ${this.get_min_bonus_eligble_match_count() - this.get_match_count()} more matches and get a 1% win bonus`

        }

        return note
    }

    get_win_value = () => {

        let netstake = this.get_stake()
        let tentedstake = this.get_configurations('MAX_WIN') / this.total_odds

        let win_value = netstake * this.total_odds


        if (win_value > this.get_configurations('MAX_WIN')) {
            let extrastake = netstake - tentedstake
            win_value = this.get_configurations('MAX_WIN') + extrastake
        }

        return win_value
    }

    calculate_tax = () => {
        return 0
    }
    get_net_pay = () => {
        let win_value = this.get_win_value()
        let bonus_value = this.get_bonus_value()
        let net_pay = win_value + bonus_value

        let win_tax = this.calculate_tax()
        net_pay = net_pay - win_tax


        return net_pay
    }
}


class MultiBonus2 extends MultiBonusMaxBonus100K{

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 500000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['MAX_BONUS'] = 100000
        all_configurations["MIN_BONUS_ODD"] = 1.5

        return all_configurations[configuration_name]

    }

    get_percentages = (match_count) => { 
        return {
            5: 0.1,
            6: 0.15,
            7: 0.20,
            8: 0.25,
            9: 0.3,
            10: 0.35,
            12: 0.4,
            13: 0.45,
            14: 0.5,
            15: 0.55,
            16: 0.6,
            17: 0.65,
            18: 0.7,
            19: 0.75,
            20: 0.8,
            21: 0.85,
            22: 0.9,
            23: 0.95,
            24: 1,
        }[match_count]

    }

    get_min_bonus_eligble_match_count = () => {
        return 5
    }

    get_max_bonus_eligble_match_count = () => {
        return 24
    }

    get_minimum_total_eligible_odd = () => {
        return this.get_configurations("MIN_BONUS_ODD") ** this.match_count
    }
    is_odd_bonus_eligible = () => {
        return this.total_odds >= this.get_minimum_total_eligible_odd()

    }

    get_note = () => {

        let percentage = this.get_percentages(this.get_percentage_match_count())
        let note = null
        let min_bonus_odd = this.get_configurations("MIN_BONUS_ODD")
        let min_bonus_total_odd = this.get_minimum_total_eligible_odd()

        if (percentage) {

            if ( this.is_odd_bonus_eligible())
                note = `Current Bonus ${(percentage * 100).toFixed(2)}%`
            else
                note = `The total value of the selected odds has to be atleast ${min_bonus_total_odd} and  each odd needs to be ${min_bonus_odd} or more`
        }
        else if (this.get_match_count() < this.get_min_bonus_eligble_match_count()) {
            note = `Select ${this.get_min_bonus_eligble_match_count() - this.get_match_count()} more matches and get a 1% win bonus`

        }

        return note
    }


}

class MultiBonus3 extends MultiBonusMaxBonus100K{

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_NONE
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 20
        all_configurations['MAX_BONUS'] = 100000
        all_configurations["MIN_BONUS_ODD"] = 1.4

        return all_configurations[configuration_name]

    }

    get_min_bonus_eligble_match_count = () => {
        return 3
    }

    get_max_bonus_eligble_match_count = () => {
        return 20
    }

    calculate_tax = () => {

        let win_value = this.get_win_value() + this.get_bonus_value()
        let tax_value = 0

        if (win_value >= this.get_configurations("TAXABLE_WIN") )
            tax_value = this.get_configurations("WIN_TAX") * win_value

        return tax_value
    }

    get_percentages = (match_count) => {
        return {
            3: 0.03,
            4: 0.04,
            5: 0.05,
            6: 0.06,
            7: 0.1,
            8: 0.15,
            9: 0.2,
            10: 0.25,
            11: 0.3,
            12: 0.35,
            13: 0.4,
            14: 0.45,
            15: 0.5,
            16: 0.55,
            17: 0.6,
            18: 0.65,
            19: 0.7,
            20: 0.75,
        }[match_count]
    }
    
    is_odd_bonus_eligible = () => {
        return this.total_odds >= this.get_configurations("MIN_BONUS_ODD") ** this.match_count

    }
}

class MultiBonus4 extends MultiBonus2{
    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 500000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['MAX_BONUS'] = 100000
        all_configurations["MIN_BONUS_ODD"] = 1.5

        return all_configurations[configuration_name]

    }

    get_percentages = (match_count) => { 
        return {
            5: 0.1,
            6: 0.15,
            7: 0.20,
            8: 0.25,
            9: 0.3,
            10: 0.35,
            11: 0.4,
            12: 0.45,
            13: 0.5,
            14: 0.55,
            15: 0.6,
            16: 0.65,
            17: 0.7,
            18: 0.75,
            19: 0.8,
            20: 0.85,
            21: 0.9,
            22: 0.95,
            23: 1,
        }[match_count]

    }

    calculate_tax = () => {

        let win_value = this.get_win_value() + this.get_bonus_value()
        let tax_value = 0

        if (win_value >= this.get_configurations("TAXABLE_WIN") )
            tax_value = this.get_configurations("WIN_TAX") * win_value

        return tax_value
    }

    get_minimum_total_eligible_odd = () => {
        return 80
    }

    get_max_bonus_eligble_match_count = () => {
        return 23
    }

    is_odd_bonus_eligible = () => {
        let min_allowed_odd = 0 

        if( this.total_odds < this.get_configurations("MIN_BONUS_ODD") ** this.match_count )
            return false


        if ( this.match_count > this.get_min_bonus_eligble_match_count())
            min_allowed_odd = this.get_configurations("MIN_BONUS_ODD") ** (this.match_count - this.get_min_bonus_eligble_match_count())

        
        return this.total_odds >= min_allowed_odd + this.get_minimum_total_eligible_odd()

    }
    
}
class MultiBonusMaxBns100KStakeWinTaxed extends MultiBonusMaxBonus100K {
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 50
        all_configurations['MAX_BONUS'] = 100000

        return all_configurations[configuration_name]

    }

    calculate_tax = () => {

        let win_value = this.get_win_value() + this.get_bonus_value()
        let tax_value = 0

        if (win_value > Constants.CONFIG.TAXABLE_WIN)
            tax_value = Constants.CONFIG.WIN_TAX * win_value

        return tax_value
    }

}


class MultiBonusMaxBns100KStakeWTaxdSlpSz35 extends MultiBonusMaxBns100KStakeWinTaxed{
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 35
        all_configurations['MAX_BONUS'] = 100000

        return all_configurations[configuration_name]

    }

}

class MultiBonusMaxBnsWinTaxBonus extends MultiBonusMaxBns100KStakeWTaxdSlpSz35 {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 35
        all_configurations['MAX_BONUS'] = 100000

        return all_configurations[configuration_name]

    }

    is_win_taxable = (amount) => {
        return amount >= this.get_configurations("TAXABLE_WIN")
    }

    calculate_tax = () => {

        let win_value  = this.get_win_value()
        let tax_value = 0

        if (this.is_win_taxable( win_value))
            tax_value = Constants.CONFIG.WIN_TAX * win_value

        return tax_value
    }

    get_win_tax_bonus = () => {
       return this.calculate_tax()        

    }

    get_bonus_value = () => {
        let multi_bonus_value = this.calculate_bonus_value()
        let win_tax_bonus = this.get_win_tax_bonus()

        let total_bonus = multi_bonus_value + win_tax_bonus
        if( total_bonus > this.get_configurations("MAX_BONUS") )
            total_bonus =  this.get_configurations("MAX_BONUS")
        
        return total_bonus
    
    }

    get_net_pay = () => {
        let win_value = this.get_win_value()
        let total_bonus =this.get_bonus_value()

        
        let net_pay = win_value + total_bonus

        let win_tax = this.calculate_tax()
        net_pay = net_pay - win_tax


        return net_pay
    }

}


class MultiBonusMaxVATWinTaxBonus extends MultiBonusMaxBnsWinTaxBonus {

    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 35
        all_configurations['MAX_BONUS'] = 100000

        return all_configurations[configuration_name]

    }


    get_bonus_value = () => {
        let multi_bonus_value = this.calculate_bonus_value()
        let win_tax_bonus = this.get_win_tax_bonus()
        let win_value = this.get_win_value()

        let total_bonus = multi_bonus_value + win_tax_bonus
        if( total_bonus > this.get_configurations("MAX_BONUS") )
            total_bonus =  this.get_configurations("MAX_BONUS")

        if( !this.is_win_taxable( win_value))
            total_bonus += this.get_initial_tax()
        
        return total_bonus
    
    }

}

class MultiBonusMaxVATWinTaxBonus2 extends MultiBonusMaxVATWinTaxBonus{
    static ConfigurationDescription = () => ({
        TAX_TYPE: Constants.TAX_TYPE_VAT
    })

    get_configurations = (configuration_name) => {

        let all_configurations = this.get_all_configurations()
        all_configurations['BET_SLIP_BONUS'] = true
        all_configurations['MAX_WIN'] = 350000
        all_configurations['SLIP_SIZE'] = 35
        all_configurations['MAX_BONUS'] = 100000

        return all_configurations[configuration_name]

    }


    get_bonus_value = () => {
        let multi_bonus_value = this.calculate_bonus_value()
        let win_tax_bonus = this.get_win_tax_bonus()

        let total_bonus = multi_bonus_value + win_tax_bonus
        if( total_bonus > this.get_configurations("MAX_BONUS") )
            total_bonus =  this.get_configurations("MAX_BONUS")

        if( !this.is_win_taxable( this.get_placed_bet() ))
            total_bonus += this.get_initial_tax() * this.total_odds
        
        return total_bonus
    
    }
}
export default {

    SlipComp: SlipComp,
    MulaSlipComp: MulaSlipComp,
    AfroSlipCompNoBonusMW50KSlipSize30: AfroSlipCompNoBonusMW50KSlipSize30,
    MulaSlipCompMW5K: MulaSlipCompMW5K,
    MulaSlipComp10PVatMW5K: MulaSlipComp10PVatMW5K,
    MulaSlipComp10PVatMW10K: MulaSlipComp10PVatMW10K,
    LesothoMulaSlipCompMW5K: LesothoMulaSlipCompMW5K,
    LesothoMulaSlipCompMW10K: LesothoMulaSlipCompMW10K,
    AfroSlipCompNoBonusMW100KSlipSize50: AfroSlipCompNoBonusMW100KSlipSize50,
    AfroSlipCompNoBonusMW350KSlipSize50: AfroSlipCompNoBonusMW350KSlipSize50,
    AfroSlipCompNoBonusMW500KSlipSize50 : AfroSlipCompNoBonusMW500KSlipSize50,
    AfroSlipCompBonusTOTMW50KSlipSize50: AfroSlipCompBonusTOTMW50KSlipSize50,
    AfroSlipCompMW50KSlpSz50BGT100BV10p: AfroSlipCompMW50KSlpSz50BGT100BV10p,
    AfroSlipCompMW350KSlpSz50BGT20BV10p: AfroSlipCompMW350KSlpSz50BGT20BV10p,
    AfroSlipCompMW350KSlpSz50BGT20BVAT: AfroSlipCompMW350KSlpSz50BGT20BVAT,
    AfroSlipCompMW1MKSlpSz50BGT20BVAT : AfroSlipCompMW1MKSlpSz50BGT20BVAT,
    AfroSlipCompMW350KSlpSz50BGT20_1000BV10p: AfroSlipCompMW350KSlpSz50BGT20_1000BV10p,
    AfroSlipCompMW350WinTaxNoStake: AfroSlipCompMW350WinTaxNoStake,
    AfroSlipCompNOBnsMW350KRfndNOTLessStake: AfroSlipCompNOBnsMW350KRfndNOTLessStake,
    AfroSlipCompBonusMW350KRfndNOTLessStake: AfroSlipCompBonusMW350KRfndNOTLessStake,
    AfroSlipComp2PRCBnsMW350KRFDNOTLTStake: AfroSlipComp2PRCBnsMW350KRFDNOTLTStake,
    AfroSlipComp10PRCBnsMW350KRFDNOTLTStake: AfroSlipComp10PRCBnsMW350KRFDNOTLTStake,
    AfroSlipMW150RFNDNOTLTSTKSLSZ50: AfroSlipMW150RFNDNOTLTSTKSLSZ50,
    AfroSlipCompNoBonusMW150KSlipSize50: AfroSlipCompNoBonusMW150KSlipSize50,
    AfroSlipCompVAT_5PRCBnsMW350KRFNOTLTSTK: AfroSlipCompVAT_5PRCBnsMW350KRFNOTLTSTK,
    AfroSlip10PRCBnsMW150RFNDNOTLTSTKSLSZ50: AfroSlip10PRCBnsMW150RFNDNOTLTSTKSLSZ50,
    AfroSlipComp10PRCBnsMW1MRFDNOTLTStake: AfroSlipComp10PRCBnsMW1MRFDNOTLTStake,
    AfroSlipCompMW350_BNSGT1000_8NOTLTSTK: AfroSlipCompMW350_BNSGT1000_8NOTLTSTK,
    AfroSlipCompMW350_BNSGT1000NOTLTSTK: AfroSlipCompMW350_BNSGT1000NOTLTSTK,
    AfroSlipCompMW350_BNSGT1000: AfroSlipCompMW350_BNSGT1000,
    AfroSlipComp10PRCBnsGT1000MW1M: AfroSlipComp10PRCBnsGT1000MW1M,
    AfroSlipComp10PRCBnsMW1M: AfroSlipComp10PRCBnsMW1M,
    AfroSlipCompMW1M_BNSGT1000: AfroSlipCompMW1M_BNSGT1000,
    MultiBonusMaxBonus100K: MultiBonusMaxBonus100K,
    MultiBonusMaxBns100KStakeWinTaxed: MultiBonusMaxBns100KStakeWinTaxed,
    MultiBonusMaxBns100KStakeWTaxdSlpSz35 : MultiBonusMaxBns100KStakeWTaxdSlpSz35,
    MultiBonusMaxBnsWinTaxBonus : MultiBonusMaxBnsWinTaxBonus,
    MultiBonusMaxVATWinTaxBonus : MultiBonusMaxVATWinTaxBonus,
    MultiBonusMaxVATWinTaxBonus2 : MultiBonusMaxVATWinTaxBonus2,
    MultiBonus2 : MultiBonus2,
    MultiBonus3 : MultiBonus3,
    MultiBonus4 : MultiBonus4
}